@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Mulish', sans-serif;
  transition: 0.25s ease-in-out;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Annie Use Your Telescope', cursive;
}

.dragging {
  color: #c6007e;
  background-color: lightgray;
  border: 2px solid #4a4a4a;
}
